import React from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./routes/ProtectedRoute";

import HomePage from "../pages/catalog/HomePage";
import CatalogPage from "../pages/catalog/CatalogPage";
import FlowersPage from "../pages/catalog/FlowersPage";
import GiftsPage from "../pages/catalog/GiftsPage";
import ProductPage from "../pages/product/ProductPage";

import LoginPage from "../pages/login/LoginPage";
import ProfilePage from "../pages/profile/ProfilePage";

import OrderPage from "../pages/order/OrderPage";
import UserOrderPage from "../pages/order/UserOrderPage";
import UserPayPage from "../pages/order/UserPayPage";
import OrderSuccessPage from "../pages/order/OrderSuccessPage";

import DeliveryPage from "../pages/static/DeliveryPage";
import ContactsPage from "../pages/static/ContactsPage";
import VacancyPage from "../pages/static/VacancyPage";
import CorpPage from "../pages/static/CorpPage";
import PolicyPage from "../pages/static/PolicyPage";

function AppNavigator() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      
      <Route path="/catalog" element={<CatalogPage />} />
 

      <Route path="/flowers" element={<FlowersPage />} />

      <Route path="/gifts" element={<GiftsPage />} />

      <Route path="/product/:title" element={<ProductPage />} />

      <Route path="/login" element={<LoginPage />} />

      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order"
        element={
          <ProtectedRoute>
            <OrderPage />
          </ProtectedRoute>
        }
      />
      <Route path="/form/:id" element={<UserOrderPage />} />
      <Route path="/pay/:id" element={<UserPayPage />} />

      <Route path="/order/success" element={<OrderSuccessPage />} />

      <Route path="/delivery" element={<DeliveryPage />} />
      <Route path="/contacts" element={<ContactsPage />} />
      <Route path="/vacancies" element={<VacancyPage />} />
      <Route path="/corp" element={<CorpPage />} />
      <Route path="/policy" element={<PolicyPage />} />

      <Route path="*" element={<CatalogPage />}  status={404} />
    </Routes>
  );
}

export default AppNavigator;
