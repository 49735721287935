import Env from "../../services/Env";
import { getSettings } from "../../services/Settings";

const moduleName = "setting";

const SET_LIST = `${moduleName}/SET_LIST`;
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const defaultState = {
    list: [],
    isFetching: false,

}

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_LIST:
            return { ...state, list: payload }

        case SET_API_REQUEST:
            return { ...state, isFetching: payload }

        default:
            return state;
    }

}



export const getSettingList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getSettings().then((res) => {
            if (res.response) {
                dispatch({
                    type: SET_LIST,
                    payload: res.response
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const getSettingValue = (_title) => (dispatch, getState) => {
    try {
        let val = null;
        let list = getState().setting.list;
        if (list.length > 0) {
             list.map((item) => {
                if (item.title == _title){ 
                    val= item.value;
                } 
            })
        }
        return val;

    } catch (error) {
        console.error(error);
    }

}

export const getSettingDelivery = (_settings, _distance) => (dispatch, getState) => {
    try {
        let price = 0;
        let maxPrice = 0;
        let list = _settings;
      
        if (list.length > 0) {
           
            list.map((item) => {
                if (item.title.includes(`sum_delivery_zone_${_distance}`)) {
                    price  = item.value;
                }

            })
            if(price == 0)  price = maxPrice;
 
        }
        return price;

    } catch (error) {
        console.error(error);
    }

}
