import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiCatalog from "../../components/catalog/UiCatalog";
import UiPageTitle from "../../components/header/UiPageTitle";

import { getStorageListByCat as getStorageListByCatAction } from "../../redux/modules/storage";

import { convertToList } from "../../utilities/storage";

import "./catalog.scss";

function FlowersPage(props) {
  const [catalogList, setCatalogList] = useState([
    { loader: true },
    { loader: true },
    { loader: true },
    { loader: true },
  ]);

 
  async function _load() {
    const list0 = await props.getStorageListByCat(4);
    setCatalogList(convertToList(list0));
  }

  useEffect(() => {
    _load();
  }, []);

  return (
    <PageContainer
      page="flowers"
      pageTitle="Круглосуточная доставка цветов в Сургуте"
      pageDescription=""
      pageKeywords=""
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/flowers", label: "Цветы" }]}
        pageName="Собрать букет из цветов"
      />
      <Container>
        <UiCatalog
          gray={true}
          title="Цветы"
          subtitle="Каталог цветов"
          list={catalogList}
        />
      </Container>
    </PageContainer>
  );
}

export default connect(({ storage }) => ({ storage }), {
  getStorageListByCat: getStorageListByCatAction,
})(FlowersPage);
