import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBasketCard from "../../components/cards/UiBasketCard";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiCheckList from "../../components/forms/UiCheckList";
import UiCheck from "../../components/forms/UiCheck";
import UiLoader from "../../components/modals/UiLoader";
import UiPageTitle from "../../components/header/UiPageTitle";
import UiSelectDate from "../../components/forms/UiSelectDate";
import UiSelectOption from "../../components/forms/UiSelectOption";
import UiTextArea from "../../components/forms/UiTextArea";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextInputMasked from "../../components/forms/UiTextInputMasked";

import OrderConstant from "../../constants/OrderConstant";

import {
  getStorageListByIdArray as getStorageListByIdArrayAction,
  getStorageListByCat as getStorageListByCatAction,
  getStorageItem as getStorageItemAction,
} from "../../redux/modules/storage";
import {
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
  getStoredUserBasket as getStoredUserBasketAction,
  changeUserBasket as changeUserBasketAction,
} from "../../redux/modules/login";
import {
  getSettingList as getSettingListAction,
  getSettingValue as getSettingValueAction,
  getSettingDelivery as getSettingDeliveryAction,
} from "../../redux/modules/setting";
import {
  updateOrder as updateOrderAction,
  getOrderId as getOrderIdAction,
} from "../../redux/modules/order";

import Env from "../../services/Env";

import { getTotalPrice } from "../../utilities/order";
import {
  toArrayList,
  toOptionsList,
  inArrayValueByKey,
} from "../../utilities/array";

import "./Order.scss";

function UserOrderPage(props) {
  let { id } = useParams();
  let navigate = useNavigate();
  const location = useLocation();

  const [order, setOrder] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [deliveryPrice, setDP] = useState(0);
  const [orderPrice, setPrice] = useState(0);
  const [freeDeliveryPrice, setFDP] = useState(3500);
  const [priceInZoneLess, setPriceInZoneLess] = useState(250);
  const [priceInZoneMore, setPriceInZoneMore] = useState(0);
  const [basketList, setBasket] = useState([]);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [address, setAddress] = useState("");
  const [comment, setComment] = useState("");
  const [commentFl, setCommentFl] = useState("");
  const [commentCurr, setCommentCurr] = useState("");

  const [movingType, setMovingType] = useState(0);

  const [distance, setDistance] = useState(null);
  const [paymentType, setPaymentType] = useState(null);

  const [deliverySurprise, setDS] = useState(true);
  const [call, setCall] = useState(false);

  const [aeroWO, setAeroWO] = useState(false);
  const [aero, setAero] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.getOrderId(id);
  }, [location]);

  useEffect(() => {
 
    if (props.order.order  && props.order.order.id )  {
      let _order = props.order.order;
      setOrder(_order);
      setPrice(_order.price);
      setDiscount(_order.price_discount);
      setDP(_order.price_delivery);
      setPhone(_order.user_phone ? _order.user_phone : _order.phone);
      setDate(new Date(_order.date));
      setTime(_order.time);
      setAddress(_order.address_a);
      setComment(_order.postcard);
      setCommentFl(_order.comment);
      setCommentCurr(_order.text);

      setName(_order.user_name);
      if (_order.composition){
       props.getStorageListByIdArray(_order.composition).then((data) => {
        setBasket(data);
       }) 
        
      } 
    }
  }, [props.order.order]);

  useEffect(() => {
    props.getStoredUserBasket();
    props.getSettingList();
  }, []);

  useEffect(() => {
    setFDP(props.getSettingValue("sum_busket_free_delivery"));
    setPriceInZoneMore(props.getSettingValue("sum_delivery_zone_more_limit"));
    setPriceInZoneLess(props.getSettingValue("sum_delivery_zone_less_limit"));
  }, [props.setting.list]);

  function checkForm() {
    return (
      (phone ? phone.length > 7 : false) &&
      time != "" &&
      ((address ? address.length > 0 : false) || aero || aeroWO) &&
      paymentType != null
    );
  }

  function edit() {
    setLoading(true);
    let _address = address;
    if (aero) _address = "Аэропорт (если курьер заходит в сам аэропорт)";
    if (aeroWO) _address = "Аэропорт (курьер не заходит в сам аэропорт)";
    props
      .updateOrder(order.id, {
        address_a: _address,
        date: date,
        time: time,
        payment_type: paymentType,
        user_phone: phone,
        user_name: name,
        postcard: comment,
        text: commentCurr,
        comment: commentFl,
        user_call: call ? 1 : 0,
      })
      .then((res) => {
        if (res) {
          navigate("/order/success");
        }
        setLoading(false);
      });
  }

  let basket = basketList.map((item, index) => {
    return (
      <UiBasketCard
        key={index}
        image={Env.PUBLIC_URL + item.images[0].name}
        title={item.title}
        text={item.text}
        price={item.price}
        num={1}
      />
    );
  });

  return (
    <PageContainer
      page="order"
      pageTitle="Круглосуточная доставка цветов в Сургуте"
      alertOpen={true}
      alertTitle="Заголовок"
      alertText="Текст сообщения"
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/order", label: "Корзина" }]}
        pageName="Оформление заказа"
      />
      <Container>
        <div className="order-wrap">
          <div className="basket-list">
            <h3>Состав заказа</h3>
            {basket}

            <p>
              Время доставки не может быть раннее, чем через 2 часа после
              оформления заказа. Доставка осуществляется круглосуточно на
              территории г. Сургут.
            </p>

            {basket.length > 0 ? (
              <div className="basket-list-coast">
                <p>Стоимость доставки</p>
                <p>
                  <strong>
                    {deliveryPrice > 0 ? deliveryPrice : "бесплатно"}
                  </strong>
                </p>
              </div>
            ) : null}
       
              <div className="basket-list-coast">
                <p>Ваша скидка на заказ составляет</p>
                <p>
                  <strong>{ discount}%</strong>
                </p>
              </div>
 
            <div className="basket-list-coast">
              <p>Общая стоимость заказа</p>
              <p>
                <strong>{orderPrice} ₽</strong>
              </p>
            </div>
          </div>
          <div className="order-info">
            <h3>Оформить заказ</h3>
            {order ? (
              <div className="order-info-input">
                <UiTextInput
                  label="Ваше имя"
                  value={name}
                  onChange={(val) => {
                    setName(val);
                  }}
                />
                <UiTextInputMasked
                  label="Ваш номер телефона"
                  value={phone}
                  onChange={(val) => {
                    setPhone(val);
                  }}
                />

                <UiCheck
                  label="Доставка-сюрприз"
                  value={deliverySurprise}
                  onChange={() => {
                    setDS(!deliverySurprise);
                    setCall(false);
                  }}
                />
                <UiCheck
                  label="Предварительный звонок"
                  value={call}
                  onChange={() => {
                    setDS(false);
                    setCall(!call);
                  }}
                />
                <small>
                  в указанное вами время получатель должен быть дома
                </small>

                <UiSelectDate
                  label="Дата доставки"
                  value={date}
                  onChange={(e) => {
                    setDate(e);
                  }}
                />

                <UiSelectOption
                  label="Время доставки"
                  value={
                    inArrayValueByKey(
                      toArrayList(OrderConstant.ORDER_TIME_TYPE),
                      "text",
                      time
                    )
                      ? inArrayValueByKey(
                          toArrayList(OrderConstant.ORDER_TIME_TYPE),
                          "text",
                          time
                        ).value
                      : null
                  }
                  optionList={toArrayList(OrderConstant.ORDER_TIME_TYPE)}
                  onChange={(e) => {
                    setTime(OrderConstant.ORDER_TIME_TYPE[e]);
                  }}
                />

                {!aeroWO && !aero ? (
                  <UiTextInput
                    label="Адрес доставки"
                    value={address}
                    onChange={(val) => {
                      setAddress(val);
                    }}
                  />
                ) : null}
                <UiCheck
                  label="Доставка в аэропорт (курьер заходит в аэропорт)"
                  value={aeroWO}
                  onChange={() => {
                    setAeroWO(!aeroWO);
                    setAero(false);
                  }}
                />
                <UiCheck
                  label="Доставка в аэропорт (курьер не заходит в аэропорт)"
                  value={aero}
                  onChange={() => {
                    setAeroWO(false);
                    setAero(!aero);
                  }}
                />
                <div id="ymap"></div>

                <UiTextArea
                  label="Комментарий"
                  onChange={(val) => {
                    setComment(val);
                  }}
                />

                <UiTextArea
                  label="Комментарий для флориста"
                  onChange={(val) => {
                    setCommentFl(val);
                  }}
                />

                <UiTextArea
                  label="Комментарий для курьера"
                  onChange={(val) => {
                    setCommentCurr(val);
                  }}
                />

                <UiCheckList
                  checkList={toOptionsList(
                    OrderConstant.ORDER_PAYMENTS_TYPES_NAMES
                  )}
                  label="Выберите способ оплаты"
                  value={paymentType}
                  onChange={(val) => {
                    setPaymentType(val);
                  }}
                />
                <UiBtnColor
                  color="pink"
                  disabled={!checkForm()}
                  onClick={() => edit()}
                  text="Сохранить"
                />
              </div>
            ) : (
              <div className="order-info-input">
                <h3> Заказ №${id} не найден :(</h3>
              </div>
            )}
          </div>
        </div>

        <UiLoader modalOpen={loading} />
      </Container>
    </PageContainer>
  );
}

export default connect(
  ({ storage, login, setting, order }) => ({ storage, login, setting, order }),
  {
    getStorageListByCat: getStorageListByCatAction,
    getStorageItem: getStorageItemAction,

    checkInUserBasket: checkInUserBasketAction,
    addUserBasket: addUserBasketAction,
    getStoredUserBasket: getStoredUserBasketAction,
    changeUserBasket: changeUserBasketAction,
    getSettingList: getSettingListAction,
    getSettingValue: getSettingValueAction,
    getSettingDelivery: getSettingDeliveryAction,

    getStorageListByIdArray: getStorageListByIdArrayAction,
    getOrderId: getOrderIdAction,
    updateOrder: updateOrderAction,
  }
)(UserOrderPage);
