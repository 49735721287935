import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
 

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiCatalog from "../../components/catalog/UiCatalog";
import UiCategoriesList from "../../components/menu/UiCategoriesList";
import UiPageTitle from "../../components/header/UiPageTitle";

import "./catalog.scss";

import { getCategoryListByTitle as getCategoryListByTitleAction } from "../../redux/modules/category";
import { getRootCategoryList as getRootCategoryListAction } from "../../redux/modules/category";

import { retrieveData, storeData } from "../../services/Storage";
import { convertToList } from "../../utilities/storage";

function GiftsPage(props) {
  let { name } = useParams();
  let navigate = useNavigate();

  const [activeFilter, setActiveFilter]= useState(null);
 
  const [homeCatalog, setHomeCatalog] = useState([
    {
      data: {
        title: ""
      },
      catalog: [
        { loader: true , is_active: 0},
        { loader: true , is_active: 0},
        { loader: true , is_active: 0},
        { loader: true , is_active: 0},
        { loader: true , is_active: 0},
        { loader: true , is_active: 0},
      ],
    },
  ]);
  const [filtersList, setFiltersList] = useState([
   
    { title_url: `/gifts/avtorskie_bukety`, title: "Гелиевые шары" },
    { title_url: `/gifts/lidery_prodazh`, title: "Мягкие игрушки" },
    { title_url: `/gifts/novinki`, title: "Открытки" },
    { title_url: `/gifts/bukety_s_rozami`, title: "Сладости" },
    { title_url: `/gifts/bukety_s_skidkami`, title: "Другое" },
  ]);


  async function _load(_name) {
    setFiltersList(
      retrieveData("menu_category") ? retrieveData("menu_category") : []
    );
    if (name) {
      const list = await props.getCategoryListByTitle(_name);
      setHomeCatalog([data]);
      const data = await props.getRootCategoryList(6);
      let arr = data.map((item) => {
        return {
          title: `${item.data.title} (${item.catalog.length})`,
          title_url: `/catalog/${item.data.title_url}`,
        };
      });
      setFiltersList(arr);
      storeData("menu_category", arr);
    } else {
      const data = await props.getRootCategoryList(5);
      setHomeCatalog(data);
      let arr = data.map((item) => {
        return {
          title: `${item.data.title} (${item.catalog.length})`,
          title_url: `/catalog/${item.data.title_url}`,
        };
      });
      setFiltersList(arr);
      storeData("menu_category", arr); 
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    _load(name);
  }, [name]);

  let catalogList = homeCatalog.map((item, index) => {
    if (item.catalog.length > 0 && (activeFilter == null  || (activeFilter == index) ) ) {
      return (
        <UiCatalog
          key={index}
          title={item.data.title}
          category={item.data.title_url}
          subtitle=""
          list={convertToList(item.catalog)}
          minimized
          categoriesLeft
        />
      );
    }
  });

  return (
    <PageContainer
      page="catalog"
      pageTitle="Круглосуточная доставка цветов в Сургуте"
      pageDescription=""
      pageKeywords=""
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/catalog", label: "Букеты" }]}
        pageName="Букеты"
      />
      <Container>
        <div className="catalog-list-categories">
          <UiCategoriesList
            categoriesList={filtersList}
            onChange={(val) => {
               setActiveFilter(val != activeFilter ? val : null)
            }}
          />
          <div>
          {catalogList}
          </div>
        </div>
      </Container>
    </PageContainer>
  );

   
}


export default connect(({ category, storage }) => ({ category, storage }), {
  getCategoryListByTitle: getCategoryListByTitleAction,
  getRootCategoryList: getRootCategoryListAction,
})(GiftsPage);
