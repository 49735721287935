import React from "react";

import { ReactComponent as SvgLogo } from "../../assets/images/logo/logo.svg";

import Container from "../container/Container";
import UiIcon from "../icon/UiIcon";

import "./UiFooter.scss";

const UiFooter = (props) => {
  const nowYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <Container>
        <div className="footer-inner">
          <div className="footer-wrap">
            <SvgLogo className="footer-logo" />
            <p>Цветочный дискаунтер в Сургуте</p>
            <p>Доставка цветов в Сургуте</p>
            <div className="footer-contact-item">
              <span>
                <UiIcon fill="dark" icon="pin" size={12} /> Сургут,
                Комсомольский проспект, 11
              </span>
              <span>
                <UiIcon fill="dark" icon="pin" size={12} /> Сургут, ​Семёна
                Билецкого, 1
              </span>
            </div>
          </div>
          <div className="footer-wrap right">
            <h3>Информация</h3>
            <div className="footer-link-item">
              <a href="/delivery">Доставка и оплата</a>
            </div>
            <div className="footer-link-item">
              <a href="/contacts">Контакты</a>
            </div>
            <div className="footer-link-item">
              <a href="/profile">Профиль пользователя</a>
            </div>
            <div className="footer-link-item">
              <a href="/policy">Политика конфиденциальности</a>
            </div>
          </div>
          <div className="footer-wrap right">
            <h3>Услуги</h3>
            <div className="footer-link-item">
              <a href="/">Онлайн витрина</a>
            </div>
            <div className="footer-link-item">
              <a href="/catalog">Авторские букеты</a>
            </div>
            <div className="footer-link-item">
              <a href="/flowers">Цветы поштучно</a>
            </div>
            <div className="footer-link-item">
              <a href="/gifts">Подарки</a>
            </div>
          </div>
        </div>
      </Container>
      <div className="sub-footer">
        <Container>
          <div className="sub-footer-wrap">
            <p>
              Copyright © {nowYear} - All rights reserved. Сайт разработан{" "}
              <a href="http://devkot.ru/index_ru.html">DEVKOT</a>
            </p>
          </div>
        </Container>
      </div>
    </footer>
  );
};
export default UiFooter;
