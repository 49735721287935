import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

 
import demoScreen from "../../assets/images/demo/1LmA0E8Dqyc.png";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiCatalog from "../../components/catalog/UiCatalog";
import UiHomeTitle from "../../components/header/UiHomeTitle";

import {
  getStorageListByCat as getStorageListByCatAction,
  getSliderList as getSliderListAction,
} from "../../redux/modules/storage";
import setting, { 
  getSettingList as getSettingListAction,
  getSettingValue as getSettingValueAction, 
} from "../../redux/modules/setting";
import { getMenuCategoryList as getMenuCategoryListAction } from "../../redux/modules/category";

import "./catalog.scss";

import { convertToList } from "../../utilities/storage";
import Env from "../../services/Env";

function HomePage(props) {
  const [promoList, setPromoList] = useState([{}]);
  const [video, setVideo] = useState(null); 


  const [homeCatalog, setHomeCatalog] = useState([
    {
      data: {
        title: ""
      },
      catalog: [
        { loader: true , is_active: 0},
        { loader: true , is_active: 0},
        { loader: true , is_active: 0},
        { loader: true , is_active: 0},
        { loader: true , is_active: 0},
        { loader: true , is_active: 0},
      ],
    },
  ]);

  async function _load() {
    const slider = await props.getSliderList();
    setPromoList(slider);

    const data = await props.getMenuCategoryList();
    setHomeCatalog(data);

    props.getSettingList().then(() => {
      let _video =  props.getSettingValue("home_video_file");
      if( _video !== null ){
        setVideo( _video );
      }
    });
  }

  useEffect(() => {
    _load();
  }, []);

  useEffect(() => { 
  
    
  }, [props.setting.list]);



  let homeList = homeCatalog.map((item, index) => {
    if (item.catalog.length > 0) {
      return (
        <UiCatalog
          key={index}
          title={item?.data?.title}
          subtitle=""
          list={convertToList(item.catalog)}
        />
      );
    }
  });

  return (
    <PageContainer
      page="home"
      pageTitle="Круглосуточная доставка цветов в Сургуте"
      pageDescription=""
      pageKeywords=""
    >
      <Container>
        <UiHomeTitle promoList={promoList} />
        {homeList}

        <div className="home-about">
          <div className="home-about-video">
            <video autoPlay loop muted>
              {video && <source
                src={Env.PUBLIC_URL + video}
                type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
              /> }
            </video>
          </div>
          <div className="home-about-info">
            <div className="home-about-info-text">
              <h3>Контроль заказа перед доставкой</h3>
              <div className="home-about-info-item">
                <span>1</span>
                <p>
                  После оформления заказа на сайте, с вами свяжется оператор и подтвердит наличие букета и цветов.
                </p>
              </div>
              <div className="home-about-info-item">
                <span>2</span>
                <p>После согласования, оператор вышлет ссылку на оплату, а после оплаты ваш заказ будет окончательно принят и оформлен, о чем вам придет уведомление в виде смс.</p>
              </div>
              <div className="home-about-info-item">
                <span>3</span>
                <p>По готовности букета, мы передадим букет в службу доставки.</p>
              </div>
            </div>
            <div className="home-about-screen">
              <img className="home-about-screen-image" src={demoScreen} />
              <div className="home-about-screen-gradient"></div>
            </div>
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default connect(({ storage, setting }) => ({ storage, setting }), {
  getSliderList: getSliderListAction,
  getStorageListByCat: getStorageListByCatAction,
  getMenuCategoryList: getMenuCategoryListAction,
  getSettingList: getSettingListAction,
  getSettingValue: getSettingValueAction,
})(HomePage);
