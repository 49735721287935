import React, { useEffect, useState } from "react";

import "./UiCheckList.scss";

const UiCheckList = (props) => {
  const [active, setActive] = useState(0);

  const setValue = (val) => {
    let new_val = val;
    setActive(new_val);
    props.onChange(new_val);
  };

  useEffect(() => {
    setActive(props.selected);
  }, [props.selected]);

  var checkList = props.checkList.map((item, index) => {
    return (
      <button
        key={index}
        className="check-item"
        onClick={() => setValue(index)}
        style={item.option == "" ?  {display: 'none'}  : null} 
      >
        <span className={"radio-icon" + (active === index ? " active" : "")} >
          {active === index ? <div className="radio-icon-active"></div> : null}
        </span>
        {item.option && item.option !== "" ? <span>{item.option}</span> : null}
      </button>
    );
  });
  return (
    <div className="check-list">
      {props.label ? <p>{props.label}</p> : null}
      {checkList}
    </div>
  );
};
export default UiCheckList;
