import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as SvgCheck } from "../../assets/icons/alert/check.svg";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiPageTitle from "../../components/header/UiPageTitle";

import "./Order.scss";

function OrderSuccessPage(props) {
  let navigate = useNavigate();

  return (
    <PageContainer
      page="order"
      pageTitle="Круглосуточная доставка цветов в Сургуте"
    >
      <UiPageTitle
        image={null}
        linksList={[{ href: "/order", label: "Корзина" }]}
        pageName="Оформление заказа"
      />
      <Container>
        <div className="order-success">
          <SvgCheck className="order-success-icon" />
          <h3>Заказ успешно создан</h3>
          <p>Ожидайте уведомления от нашего менеджера</p>
          <UiBtnColor
            color="pink"
            onClick={() => navigate("/profile")}
            text="Профиль"
          />
        </div>
      </Container>
    </PageContainer>
  );
}

export default connect(
  ({ storage, login, setting }) => ({ storage, login, setting }),
  {}
)(OrderSuccessPage);
